import React, { useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  getLineChartData,
  getLineChartOptions,
  getSelectedDatesKey,
  processData
} from "../../chart-utils.js";
import { useSelector } from "react-redux";
import BlockUi from "react-block-ui";

const TemperatureChart = ({ selectedDates, measureNameGroup, onFetchMetrics }) => {
  const timeHistoryState = useSelector(state => state.timeHistory);
  const { data: deviceTimeHistory, loadingIds } = timeHistoryState;

  const isLoading = loadingIds.indexOf(measureNameGroup.id) !== -1;

  const selectedDatesKey = getSelectedDatesKey(selectedDates);
  const { timestamps = [], metrics = {} } = useMemo(
    () => (deviceTimeHistory[selectedDatesKey] || []).find(it => it.id === "temperature")?.results || {},
    [selectedDates, deviceTimeHistory]
  );

  const actualTempSeries = useMemo(
    () => processData(timestamps, metrics, "ActualTemperature", "float", true),
    [metrics]
  );

  const heatSetpointSeries = useMemo(
    () => processData(timestamps, metrics, "HeatSetpoint", "float", true),
    [metrics]
  );

  const coolSetpointSeries = useMemo(
    () => processData(timestamps, metrics, "CoolSetpoint", "float", true),
    [metrics]
  );

  const dischargeTempSeries = useMemo(
    () => processData(timestamps, metrics, "DischargeTemperature", "float", true),
    [metrics]
  );
  const returnTempSeries = useMemo(
    () => processData(timestamps, metrics, "ReturnTemperature", "float", true),
    [metrics]
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const selectedDatesKey = getSelectedDatesKey(selectedDates);

    if (
      !deviceTimeHistory[selectedDatesKey] ||
      !deviceTimeHistory[selectedDatesKey].find(it => it.id === "temperature")
    ) {
      onFetchMetrics(measureNameGroup);
    }
  }, [selectedDates, deviceTimeHistory]);

  const chartTitles = [
    'Actual Temperature',
    'Heat Setpoint',
    'Cool Setpoint',
    'Discharge Temperature',
    'Return Temperature'
  ];
  const chartSeries = [actualTempSeries, heatSetpointSeries, coolSetpointSeries, dischargeTempSeries, returnTempSeries];

  return (
    <BlockUi tag="div" blocking={isLoading} message="Loading, please wait...">
      <div className="chart-row">
        <div className="chart-line">
          <Line
            width={"100%"}
            height={"400px"}
            data={getLineChartData(chartTitles, chartSeries)}
            options={getLineChartOptions("Temperature (°F)", "°F", 40, 140, 10)} />
        </div>
      </div>
    </BlockUi>
  )
};

export default React.memo(TemperatureChart);
